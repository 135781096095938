<template>
  <v-container class="container" fluid>
    <div class="d-flex flex-row align-center">
      <!-- filter panel -->
      <GsDataFilter
        style="width: 100%"
        ref="dataFilter"
        :appSetting="appSettingKey"
        :filters="filters"
        v-on:changed="onFilterChanged"
      />
    </div>
    <ManageGroups :config="config" v-if="customerUri" />
  </v-container>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import { GsDataFilter } from "ngt-frontend-core";
import { ManageGroups } from "ngt-group-manager-lib";

export default {
  name: "Groups",
  components: {
    GsDataFilter,
    ManageGroups
  },
  data() {
    return {
      appSettingKey: "groups",
      initialized: false,
      filters: [
        {
          field: "ownedBy",
          component: "GsInfiniteDropdownFilter",
          params: {
            options: [],
            clearable: false
          },
          config: {
            dataConnector: callBffAPI,
            dataFields: {
              uri: "/customers",
              sort: "name:asc",
              fields: "name,_uri",
              search: "name:ic:",
              dataMap: {
                title: "name",
                value: "_uri"
              }
            },
            dense: true,
            solo: true,
            flat: true,
            limit: 50,
            page: 0,
            value:
              this.$store.state?.appSettings?.groups?.filter?.ownedBy?.value ||
              this.customerUri
          },
          width: "longest"
        }
      ],
      selectedGroup: null,
      reload: false,
      customerUri: null
    };
  },
  computed: {
    config() {
      return {
        dataConnector: callBffAPI,
        forSuperAdmins: true,
        customerUri: this.customerUri
      };
    }
  },
  async mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
    await this.update();
  },
  methods: {
    async update() {
      const filter = this.$refs.dataFilter.getFilter();
      const customerUri = filter.substring("&filter=ownedBy:eq:".length);
      this.customerUri = customerUri;
    },
    createRootGroup_click() {},
    selectGroup(id) {
      this.selectedGroup = id;
    },
    reloadGroups() {
      this.reload = true;
    },
    groupsReloaded() {
      this.reload = false;
    },
    async onFilterChanged() {
      await this.update();
    }
  }
};
</script>

<style lang="scss"></style>
